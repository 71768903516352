$color-primary: #FC5E71;
$color-success: #1edf84;
$color-info: #5edbe9;
$color-warning: #EDA944;

$color-white: #fff;
$color-white-muted: rgba(255, 255, 255, 0.5);
$color-white-muted-highlight: rgba(255, 255, 255, 0.85);
$color-black: #000;
$color-black-muted: rgba(0, 0, 0, 0.5);
$color-black-muted-highlight: rgba(0, 0, 0, 0.85);

$image-mobile-height: 600px;

$drop-shadow: 0 0 10px 0 rgb(82 63 105 / 5%);

$color-desert-storm: #edebe9;
$color-narvik: #E7E6E1;

$color-lighter-blue: #e7e7ff;
$color-light-blue: #c8c8ff;
$color-lt-blue: #7777cc;
$color-blue: #3f51b5;