.toast-top-right {
    top: 72px !important;
}

.ngx-toastr {
    font-family: 'Montserrat';
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;

    &:hover {
        box-shadow: 0px 2px 3px -1px rgb(0 0 0 / 40%), 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 15%) !important;
    }

    .toast-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .03em;
    }

    .toast-message {
        font-size: 13px;
        font-weight: 200;
        letter-spacing: .03em;
    }
}