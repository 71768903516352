@use '@angular/material' as mat;
@import '/src/assets/styles/variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: unquote('Nunito, "Open Sans", sans-serif')
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$biohackk-landing-primary: mat.define-palette(mat.$grey-palette, 900);
$biohackk-landing-accent: mat.define-palette(mat.$deep-purple-palette);

// The warn palette is optional (defaults to red).
$biohackk-landing-warn: mat.define-palette(mat.$red-palette, 300);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$biohackk-landing-theme: mat.define-light-theme((
  color: (
    primary: $biohackk-landing-primary,
    accent: $biohackk-landing-accent,
    warn: $biohackk-landing-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($biohackk-landing-theme);
@import './assets/styles/vendors/headroom';
@import './assets/styles/vendors/material';
@import './assets/styles/vendors/nebular';
@import './assets/styles/vendors/swal';
@import './assets/styles/vendors/animate';
@import './assets/styles/vendors/phone-input';
@import './assets/styles/vendors/tooltip';
@import './assets/styles/vendors/avatar';
@import './assets/styles/vendors/toastr';
@import './assets/styles/vendors/dropzone';
@import './assets/styles/vendors/datatable';
@import './assets/styles/vendors/fullcalendar';

/* You can add global styles to this file, and also import other style files */

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Spectral:wght@200;300;400;500;600;700;800&family=Gochi+Hand&display=swap;');

// @pqina/flip  
// @import url('https://unpkg.com/@pqina/flip/dist/flip.min.css');

// Custom Bootstrap Styles
$primary: $color-primary;
$danger: $color-primary;
$success: $color-success;
$info: $color-info;
$warning: $color-warning;
@import "../node_modules/bootstrap/scss/bootstrap";

// Swiper Module
@import '../node_modules/swiper/swiper-bundle';
@import './assets/styles/vendors/swiper';

// Toastr Module
@import '../node_modules/ngx-toastr/toastr';

// Datatable Module
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

// import Nebular Theme System and the default theme
@import '../node_modules/@nebular/theme/styles/theming';
@import '../node_modules/@nebular/theme/styles/themes/corporate';
@import '../node_modules/@nebular/theme/styles/globals';
@import '../node_modules/@nebular/auth/styles/globals';

// import application custom styles
@import './assets/styles/public';
@import './assets/styles/custom';

$nb-themes: nb-register-theme((

  layout-padding: 0,
  layout-medium-padding: 0,
  layout-small-padding: 0,
  
  font-family-primary: unquote('Nunito, "Open Sans", sans-serif'),
  font-family-secondary: unquote('Poppins, "Open Sans", sans-serif'),

  text-hint-color: #AAAAAA,
  color-primary-100: #FEE6DE,
  color-primary-200: #FEC8BE,
  color-primary-300: #FEA49E,
  color-primary-400: #FD8689,
  color-primary-500: $color-primary,
  color-primary-600: #D84464,
  color-primary-700: #B52F58,
  color-primary-800: #921D4C,
  color-primary-900: #781244,
  color-primary-transparent-100: rgba(252, 94, 113, 0.08),
  color-primary-transparent-200: rgba(252, 94, 113, 0.16),
  color-primary-transparent-300: rgba(252, 94, 113, 0.24),
  color-primary-transparent-400: rgba(252, 94, 113, 0.32),
  color-primary-transparent-500: rgba(252, 94, 113, 0.4),
  color-primary-transparent-600: rgba(252, 94, 113, 0.48),
  color-success-100: #CEFDD4,
  color-success-200: #9EFCB4,
  color-success-300: #6DF79A,
  color-success-400: #48EF90,
  color-success-500: #10E581,
  color-success-600: #0BC480,
  color-success-700: #08A47A,
  color-success-800: #05846F,
  color-success-900: #036D66,
  color-success-transparent-100: rgba(16, 229, 129, 0.08),
  color-success-transparent-200: rgba(16, 229, 129, 0.16),
  color-success-transparent-300: rgba(16, 229, 129, 0.24),
  color-success-transparent-400: rgba(16, 229, 129, 0.32),
  color-success-transparent-500: rgba(16, 229, 129, 0.4),
  color-success-transparent-600: rgba(16, 229, 129, 0.48),
  color-info-100: #D3FEF4,
  color-info-200: #A7FDF1,
  color-info-300: #7BF9F1,
  color-info-400: #59F1F4,
  color-info-500: #25D9ED,
  color-info-600: #1BABCB,
  color-info-700: #1282AA,
  color-info-800: #0B5E89,
  color-info-900: #074471,
  color-info-transparent-100: rgba(37, 217, 237, 0.08),
  color-info-transparent-200: rgba(37, 217, 237, 0.16),
  color-info-transparent-300: rgba(37, 217, 237, 0.24),
  color-info-transparent-400: rgba(37, 217, 237, 0.32),
  color-info-transparent-500: rgba(37, 217, 237, 0.4),
  color-info-transparent-600: rgba(37, 217, 237, 0.48),
  color-warning-100: #FEF5D9,
  color-warning-200: #FDE9B4,
  color-warning-300: #F9D78E,
  color-warning-400: #F4C471,
  color-warning-500: #EDA944,
  color-warning-600: #CB8731,
  color-warning-700: #AA6822,
  color-warning-800: #894C15,
  color-warning-900: #71380D,
  color-warning-transparent-100: rgba(237, 169, 68, 0.08),
  color-warning-transparent-200: rgba(237, 169, 68, 0.16),
  color-warning-transparent-300: rgba(237, 169, 68, 0.24),
  color-warning-transparent-400: rgba(237, 169, 68, 0.32),
  color-warning-transparent-500: rgba(237, 169, 68, 0.4),
  color-warning-transparent-600: rgba(237, 169, 68, 0.48),
  color-danger-100: #FEE6DE,
  color-danger-200: #FEC8BE,
  color-danger-300: #FEA49E,
  color-danger-400: #FD8689,
  color-danger-500: $color-primary,
  color-danger-600: #D84464,
  color-danger-700: #B52F58,
  color-danger-800: #921D4C,
  color-danger-900: #781244,
  color-danger-transparent-100: rgba(252, 94, 113, 0.08),
  color-danger-transparent-200: rgba(252, 94, 113, 0.16),
  color-danger-transparent-300: rgba(252, 94, 113, 0.24),
  color-danger-transparent-400: rgba(252, 94, 113, 0.32),
  color-danger-transparent-500: rgba(252, 94, 113, 0.4),
  color-danger-transparent-600: rgba(252, 94, 113, 0.48)

), biohackk, corporate);

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
};
