@mixin static-overlay {
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        background: url('https://ik.imagekit.io/biohackk/assets/static-overlay.png?tr=h-200');
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }
}