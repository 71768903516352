// Material Buttons
.mat-button, .mat-flat-button, .mat-icon-button {
    outline: none !important;

    .mat-button-focus-overlay { opacity: 0 !important; }
}

.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: 0 !important;
    color: rgba(0, 0, 0, 0.26) !important;
    cursor: not-allowed !important;
}



// Material Drawer
.mat-drawer-container {
    background-color: transparent !important;

    .mat-drawer-inner-container {
        position: relative;

        .nav-bottom {
            // margin-top: 150px;
            // position: absolute;
            // bottom: 0;
            width: 100%;
            border-top: 1px solid #ddd;
        }
    }
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.4);
}

.cdk-overlay-container {
    .bct-dialog mat-dialog-container {
        padding: 0 !important;
        overflow: hidden;
    }

    .mat-dialog-content {
        max-height: calc(100vh - 200px) !important;
    }
} 



// Material Menu
.mat-menu-panel {
    margin-top: -2px;
    // margin-left: -21px;
    box-shadow: 0px 1px 5px #55555522;
    background: radial-gradient(white, #f0f0f0);
    background-size: 400% 200%;
    background-position: bottom;
    border: 1px solid #eee;
    max-width: unset !important;
    
    .mat-menu-content {
        padding: 0 !important;

        button.mat-menu-item, a.mat-menu-item {
            font-size: 11px;
            font-weight: 600;
            // text-transform: uppercase;
            letter-spacing: 0.13em;
            font-family: "Montserrat";
            color:#999;
            border-left: 3px solid transparent;
            text-decoration: none;
            line-height: 30px;
            padding: 5px 15px;

            .mat-menu-item-submenu-trigger::after {
                border-color: transparent transparent transparent #999;
            }

            &.active:not([disabled]), &:hover:not([disabled]), &.cdk-program-focused:not([disabled]), &.cdk-keyboard-focused:not([disabled]), &.mat-menu-item-highlighted:not([disabled]) {
                color: black;
                background: rgba(0,0,0,0.01);

                .mat-menu-item-submenu-trigger::after {
                    border-color: transparent transparent transparent black;
                }
            }

            &.active {
                border-left: 3px solid #FC5E71;
            }

            i {
                font-size: 20px;
                vertical-align: middle;
                margin-top: -3px;
                margin-right: 5px;
                opacity: .5;
            }
        }
    }
}



// Material Card
mat-card {
    .mat-card-header {
        .mat-card-header-text {
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            mat-card-title {
                font-family: 'Montserrat';
                font-size: 1.3rem !important;
                font-weight: 600 !important;
                line-height: 1.3;
                margin: 0;
            }
        
            mat-card-subtitle {
                font-family: 'Montserrat';
                font-size: 0.75rem !important;
                letter-spacing: .05em;
                line-height: 1.3;
                margin: 0;
    
                &:not(:first-child) {
                    margin-top: 2px;
                }
            }
        }

        .mat-card-header-buttons {
            .mat-flat-button {
                padding: 0;
                min-width: 44px;
                min-height: 44px;
                font-size: 35px;

                &:not(.active) {
                    color: #ccc;
                }

                &:hover {
                    color: black;
                }

                &.btn-refresh {
                    font-size: 28px;
                }
            }

            .icons-divider {
              display: inline-flex;
              height: 15px;
              width: 1px;
              margin: auto;
              vertical-align: middle;
              background: #ddd;
              margin: 8px 15px 0 12px;
            }
        }

        &:not(.buttons-left) .mat-card-header-buttons { margin-left: auto; }
        &.buttons-left {
            flex-direction: row-reverse;

            .mat-card-header-text {
                margin-right: auto;
            }
            
            .mat-card-header-buttons { 
                margin-right: .75rem;

                .mat-flat-button { min-width: unset !important; }
                button.btn-back i { font-size: 25px; }
            }
        }
    }
}

// Optimize for Mobile
@media (max-width: 599.98px) {
    mat-card .mat-card-header {
        .mat-card-header-text {
            mat-card-title {
                font-size: 1rem !important;
            }
            mat-card-subtitle {
                font-size: 0.55rem !important;
            }
        }
        .mat-card-header-buttons .mat-flat-button {
            min-width: 25px;
            min-height: 30px;
            font-size: 25px;
            line-height: 1;

            &.btn-refresh {
                font-size: 18px;
            }
        }
    }
}



// Material Form Field
.mat-form-field {

    .btn-clear, .btn-view { 
        font-size: 1.8em !important; 
        color: #bfbfbf; 
        transition: .3s ease;

        &:hover {
            color: black;
        }
    }
    .mat-form-field-label, .mat-hint, mat-label { letter-spacing: .03em; }
    
    &:not(.mat-form-field-invalid) {
        .mat-form-field-underline, 
        .mat-form-field-subscript-wrapper,
        .mat-select-arrow-wrapper { transition: .3s ease; opacity: .4; }
    }

    &.mat-hint-visible .mat-form-field-subscript-wrapper { opacity: 1 !important; }
    
    &:not(.mat-read-only) {
        &.mat-focused, &:hover {
            .mat-form-field-underline, 
            .mat-form-field-subscript-wrapper,
            .mat-select-arrow-wrapper { opacity: 1; }
        }
    }

    &.mat-read-only {
        * { 
            pointer-events: none; 
            cursor: not-allowed; 
        }
        
        mat-label { opacity: .75; }
        .btn-clear { display: none; }
        .mat-form-field-subscript-wrapper { opacity: 0; }
        .mat-form-field-underline,
        button.mat-menu-trigger.country-selector { opacity: 0.2 !important; }

        input, .mat-select-value, .mat-datepicker-toggle, .mat-form-field-suffix, textarea { color: #999; }
    }

    .input-suffix {
        letter-spacing: .08em;
        font-size: 11px;
        font-weight: 600;
    }

    input, .mat-select-value-text, textarea {
        font-weight: 500;
        font-family: 'Montserrat';
        line-height: 1.3;
        letter-spacing: 0.05em;

        &[readonly] {
            color: #999;
        }
    }

    textarea {
        &.mat-autosize { max-height: unset !important; }
    }

    &.mat-big {
        .mat-form-field-label {
            white-space: normal;
            line-height: 1.25;
        }
    
        mat-hint, mat-error {
            letter-spacing: 0.03em;
        }
    
        .mat-form-field-underline {
            height: 2px;
        }

        @media (max-width: 499px) {
            input, .mat-select-value-text, textarea, label, .mat-form-field-infix {
                font-size: 1.5rem;
            }
    
            &.mat-form-field-should-float label {
                font-size: 1.25rem;
            }
    
            .mat-form-field-suffix .mat-icon-button {
                font-size: 1.25rem;
            }

            ngx-mat-intl-tel-input {
                .country-selector {
                    width: 110px;
                    background-size: 20px auto;
                }
    
                input:not(.country-search) {
                    padding-left: 110px;
                }
            }
        }

        @media (min-width: 500px) {
            input, .mat-select-value-text, textarea, label, .mat-form-field-infix {
                font-size: 2rem;
            }

            mat-hint, mat-error {
                font-size: 1rem;
            }
    
            &.mat-form-field-should-float label {
                font-size: 1.5rem;
            }
    
            .mat-form-field-suffix .mat-icon-button {
                font-size: 1.5rem;
            }

            ngx-mat-intl-tel-input {
                .country-selector {
                    width: 150px;
                    background-size: 20px auto;
                }
    
                input:not(.country-search) {
                    padding-left: 150px;
                }
            }
        }
    }
}

.mat-radio-group.mat-big {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .mat-radio-button {
        margin: 5px 0;

        .mat-radio-label {
            padding: 3px 0 3px 8px;
            border-radius: 10px;
            
            .mat-radio-label-content {
                // font-size: 2rem;
                // line-height: 1.5; 
                font-size: 1.25rem;
                line-height: 1.5;
                font-weight: 700;
            }
        }

        &.mat-radio-checked {
            .mat-radio-label {
                background: black;
                color: white;
            }
            .mat-radio-outer-circle {
                border-color: white;
            }
            .mat-radio-inner-circle, 
            .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
            .mat-radio-persistent-ripple,
            :active .mat-radio-persistent-ripple {
                background-color: white;
            }
        }
    }
}

.mat-flat-button.mat-big {
    font-size: 1.5rem;
    line-height: 1.75;
}



// Material Step
.mat-stepper-horizontal {
    .mat-step-label {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.03em;
        font-family: 'Poppins';
    }
    .mat-horizontal-content-container {
        padding-bottom: 0;
    }
}






// Material Select
mat-select.data-category {    
    letter-spacing: .5px;
    font-size: 20px;
    
    .mat-select-value {
        max-width: unset;
        color: #665dda;
    }

    .mat-select-arrow-wrapper {
        padding-left: 10px;

        .mat-select-arrow {
            width: 8px;
            height: 8px;
            border-left: 2px solid #665dda;
            border-bottom: 2px solid #665dda;
            border-right: 0;
            border-top: 0;
            margin: 5px 0px;
            transform: rotate(-45deg);
            display: inline-block;
        }
    }
}
.mat-select-panel.data-category {
    .mat-option-text {
        font-size: 20px;
        letter-spacing: .5px;
        font-weight: 600;
        color: #665dda;
    }
}   
.mat-option.mat-option-disabled.fst-italic {
    line-height: 2.5em;
    height: 2.5em;
    font-size: 90%;
}





// Material Timepicker
.time-frame {
    font-family: 'Montserrat';
    font-weight: 600;
}

.ampm {
    letter-spacing: 0.05em;
    font-family: 'Montserrat';
    font-weight: 700;
}




// Material Dialog
.mat-dialog-no-padding {
    .mat-dialog-container {
        padding: 0 !important;
        overflow-x: hidden;
    }
}



// Material Checkbox
.mat-checkbox {
    &.mat-read-only {
        .mat-checkbox-inner-container { opacity: 0; }
        .mat-checkbox-label { color: #555; }
    }
    &.checklist {
        .mat-checkbox-layout {
            width: 100%;
            padding: 15px;
    
            .mat-checkbox-label {
                flex-grow: 1;
                line-height: 24px;
                font-family: 'Montserrat';
                font-weight: 500;
                letter-spacing: .01em;
                color: #555;
                padding-left: 3px;
            }
        
        }
    }
}


.form-title-floating {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    transform: scale(0.75) translateY(-6px);
    transform-origin: 0;
    margin-bottom: 0px;
}





mat-timepicker-dialog {
    .selected { color: white; }
    .placeholder {
        display: flex !important;
        cursor: initial !important;
        background-color: transparent !important;
    }
}