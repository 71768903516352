:root {
    --swiper-navigation-color: black;
    --swiper-pagination-color: #FC5E71;
}

.swiper-container.home {
    height: calc(90vh - 75px);
    max-height: 50vw;
    background: white;

    .swiper-slide {
        > * {
            display: flex;
            height: 100%;
        }
    }

    .swiper-button-prev.swiper-button-disabled, 
    .swiper-button-next.swiper-button-disabled,
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
        opacity: 0.1;
    }

    .swiper-pagination-progressbar {
        background: #FC5E7133;
    }
}


// Homepage
.home #slide-1 {
    h1 {
        font-family: 'Poppins';
        font-size: 3.5rem !important;
        line-height: 1;
    }
    .typed-cursor {
        font-size: 4rem !important;
    }
    p.sub-title {
        font-weight: 200;
        letter-spacing: .05em;
        font-size: 1.45rem;
    }
    .cta {
        margin-top: 40px;

        button {
            text-transform: uppercase;
            letter-spacing: .1em;

            &.offer {
                padding: 5px 30px 5px 65px;
                color: white;
                transition: .1s ease-in-out;
                transform-origin: left;
                background: radial-gradient(#ff9f9f, #ff4d6d);
                background-size: 200% 300%;
                background-position: bottom right;
                border: 2px solid #ffb2b2;
        
                &:hover {
                    transform: scale(1.03);
                }
        
                &:before {
                    content: '';
                    height: 30px;
                    width: 35px;
                    background: url('https://ik.imagekit.io/biohackk/assets/icon-drop-fit-transparent.png?tr=h-30');
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left center;
                    border-right: 1px solid #ffaaad;
                    transform: translateY(-50%);
                }
            }

            &.more {
                padding: 5px 25px;
                font-size: 11px;
                margin-left: 20px;
                margin-top: 0px;
                margin-right: 1px;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .home #slide-1 {
        h1 {
            font-size: 3rem !important;
        }
        .typed-cursor {
            font-size: 3.5rem !important;
        }
        p.sub-title {
            font-size: 1.25rem;
        }
        .cta {
            margin-top: 30px;

            button {
                &.offer {
                    font-size: 12px;
                    padding: 4px 25px 4px 60px;
                    font-size: 12px;
        
                    &:before {
                        height: 28px;
                        width: 33px;
                    }
                }

                &.more {
                    font-size: 11px; 
                    padding: 4px 25px;
                    margin-left: 20px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .home #slide-1 {
        h1 {
            font-size: 2.5rem !important;
        }
        .typed-cursor {
            font-size: 3rem !important;
        }
        p.sub-title {
            font-size: 1.05rem;
        }
        .cta {
            margin-top: 20px;

            button {
                &.offer {
                    font-size: 11px;
                    padding: 3px 20px 3px 55px;
        
                    &:before {
                        height: 25px;
                        width: 28px;
                    }
                }

                &.more {
                    font-size: 11px; 
                    padding: 3px 20px;
                    margin-left: 20px;
                }
            }
        }
    }
}

@media (min-width: 501px) and (max-width: 767px) {
    .home #slide-1 {
        h1 {
            font-size: 2rem !important;
        }
        .typed-cursor {
            font-size: 2.5rem !important;
        }
        p.sub-title {
            font-size: .85rem;
        }
        .cta {
            margin-top: 10px;

            button {
                
                &.offer {
                    font-size: 10px;
                    padding: 2px 18px 2px 50px;
        
                    &:before {
                        height: 22px;
                        width: 25px;
                    }
                }

                &.more {
                    font-size: 10px; 
                    padding: 2px 18px;
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .home #slide-1 {
        h1 {
            font-size: 1.5rem !important;
        }
        .typed-cursor {
            font-size: 2rem !important;
        }
        p.sub-title {
            font-size: .65rem;
        }
        .cta {
            margin-top: 0px; 

            button {
                &.offer {
                    font-size: 9px;   
                    padding: 0px 13px 0px 41px;
        
                    &:before {
                        left: 10px;
                        height: 20px;
                        width: 22px;
                    }
                }

                &.more {
                    font-size: 9px; 
                    padding: 0px 13px;
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}