.swal2-title {
    font-weight: 300 !important;
    letter-spacing: .03em !important;
}

.swal2-content {
    font-weight: 300 !important;
    letter-spacing: .05em !important;
    font-size: .8rem !important;
    line-height: 1 !important;
}

.swal2-styled {
    padding: .8em 1.5em !important;
    box-shadow: none;
    font-weight: 600 !important;
    letter-spacing: .1em !important;
    font-size: .85rem !important;
    text-transform: uppercase !important;
}

.swal2-styled:focus {
    box-shadow: 0 0 0 3px rgb(180 180 180 / 50%) !important;
}