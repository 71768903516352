@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

* {
    outline: 0 !important;
}

html {
    max-width: 100vw;
    overflow-x: hidden;
}

.pointer {
    cursor: pointer;
}

.help {
    cursor: help;
}

body, iframe {
    &::-webkit-scrollbar { display: none; }
    -webkit-text-size-adjust: none;
    padding: 0;
}

.z1 {
    z-index: 1;
}

.lh-1 { line-height: 1; }

.bg-purple { background-color: purple !important; }
.text-purple { color: purple !important; }

.text-link {
    text-decoration: none;
    transition: .3s ease;
    cursor: pointer;
    
    &:hover {
        color: #777 !important;
        border-bottom: 1px dashed #bbb;
    }
}

.text-highlight {
    transition: .3s ease;
    color: #777 !important;
    border-bottom: 1px dashed #bbb;
    z-index: 1;
    position: relative;

    &:hover {
        color: black !important;
        border-bottom: 1px solid black;
    }
}

.text-justify {
    text-align: justify;
}

.text-clip {
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.min-height-100vh {
    min-height: 100vh;
}

.static-overlay {
    @include static-overlay;
}

.border-top,
.border-bottom,
.border-start,
.border-end {
    border-color: rgba(255, 99, 132, 0.2) !important;
}


.hint {
    color: #999;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: 2;
    font-size: 12px;

    &:not(.fal):not(.fas):not(.far):not(.fad):not(.fab) {
        font-family: 'Montserrat';
    }
}


.mat-button-toggle-group {
    border: 3px dashed #f0f0f0; 

    .mat-button-toggle-checked {
        // background-color: #f0f0f0;
        background-color: #eeeeff;
    }

    &.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
        border-left: 3px dashed #f0f0f0; 
    }
}


// Dropzone Image
.upload-image{
    height: 270px !important;
    border: 3px dashed #f0f0f0 !important;
    border-radius: 10px !important;
    color: #b1b5d4 !important;
    overflow: hidden;

    &:hover {
        border-color: #a34a4a55 !important;
        color: #a34a4a !important;
        transition: .3s ease;
    }

    .image-preview {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;   
        height: 100%;
        width: 100%;
    }
    
    .profile-wrapper {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 0 auto 10px;

        ik-image {
            transform: scale(2) translateY(30px);
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            background: radial-gradient(#431919, #fa757c);
        }
    }

    ngx-dropzone-label {
        ik-image {
            width: 100px;
            height: 150px;
            display: inline-flex;
            margin: 0 auto;
        }
        .title {
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-weight: 800;
            font-size: 12px;
        }
        .description {
            font-size: 11px;
            line-height: 1.5;
            letter-spacing: 0.03em;
        }
    }
}

.btn-remove-image {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: white !important;
}

@media (max-width: 767px) {
    .upload-image {
        margin-bottom: 25px;
    }
}


.overlay-counter {
    &:before {
        content: '';
        position: absolute;
        background: black;
        height: 15px;
        padding: 0 5px;
        bottom: 0px;
        border-radius: 100px;
        color: white;
        font-size: 10px;
        line-height: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat";
        font-weight: 700;
        transform: translate(6px, 2px);
        z-index: 1;
        right: 15px;
        left: initial;
    }

    @for $i from 1 through 99 {
        &.c-#{$i}:before { content: '#{$i}'; }
    }
    &.c-more:before { content: '99+'; }
}



.file-container { 
    padding: 15px 25px; 
    border-top: 1px solid #eee; 
    border-bottom: 1px solid #eee; 

    &:hover { background: #fbfbff; }
    &:first-of-type { border-top: 1px solid #eee; }

    i { font-size: 50px; }
    
    .title {
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.03em;
        color: #067e89;
    }

    .description { 
        font-size: 9px; 
        span.info:not(:first-child):before { content: ' | '; opacity: 0.25; padding: 0 3px; }
    }

    .btn-download {
        text-decoration: none;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        background: #25D9ED22;
        padding: 8px 15px;
        border-radius: 50px;
        margin-left: auto;
        line-height: 1;
        min-width: 100px;
    }
}



@keyframes flippingFront {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(180deg); }
}
@keyframes flippingBack {
    0% { transform: rotateY(180deg); }
    100% { transform: rotateY(360deg); }
}

@keyframes flippingFrontOut {
    0% { transform: rotateY(180deg); }
    100% { transform: rotateY(0deg); }
}
@keyframes flippingBackOut {
    0% { transform: rotateY(360deg); }
    100% { transform: rotateY(180deg); }
}
  
.flip {
    position: relative;
    perspective: 30rem;
    transform-style: preserve-3d;
    height: 285px;
    z-index: 1;

    &.reverse {
        .front {
            animation: flippingFront 1s forwards;
        }
        
        .back {
            animation: flippingBack 1s forwards;
        }
    }

}
.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    transition: transform 1s;
}
.front {
    background-color: transparent;
    transform: rotateY(0deg);
    animation: flippingFrontOut 1s forwards;
}
.back {
    background-color: transparent;
    animation: flippingBackOut 1s forwards;
}

.profile-badge {
    background: #f0f0f0;
    padding: 3px 6px;
    font-size: 9px;
    vertical-align: middle;
    border-radius: 3px;
    font-weight: 600;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    line-height: 2;
    font-family: "Montserrat";
}

.points-card {
    height: 285px;
    background: linear-gradient(27deg, black, #333);
    border: 1px solid #555;

    &:before {
        content: '';
        background: url(https://ik.imagekit.io/biohackk/images/stripes.png?tr=h-1000);
        background-size: 1000px;
        filter: invert(1);
        background-position: -130px bottom;
    }

    mat-card-content {
        color: white;
        font-family: 'Montserrat';
        line-height: 1;
        text-align: center;
        text-shadow: 0 0 2px black;
        height: 100%;
        display: block;

        &:before {
            content: "";
            border: 10px solid black;
            position: absolute;
            top: 15px;
            bottom: 15px;
            left: 15px;
            right: 15px;
            opacity: .25;
        }

        &:after {
            content: "";
            background: url(https://ik.imagekit.io/biohackk/assets/text-fit-white.png?tr=h-200);
            position: absolute;
            top: 35px;
            left: 35px;
            right: 35px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .points {
            font-weight: 600;
            font-size: 50px;
            padding-top: 50px;
            display: block;
            line-height: 1.2;
            letter-spacing: 0.03em;
            margin-top: 5px;
        }

        .description {
            display: block;
            font-size: 20px;
            line-height: 1.5;
        }

        .credits {
            font-weight: 600;
            font-size: 30px;
            display: block;
            line-height: 1.2;
            letter-spacing: 0.03em;
            margin-top: 15px;
        }

        .credits-desc {
            display: block;
            font-size: 15px;
            line-height: 1.5;
        }

        .timestamp {
            display: block;
            font-size: 10px;
            line-height: 1.5;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            opacity: .75;
            margin-top: 5px;
        }

        .qr-code {
            position: absolute;
            top: 35px;
            right: 35px;
            font-size: 40px;
            z-index: 11;
        }
    }
}




// Client Programme
.programme-active,
.programme-inactive,
.programme-completed {
    font-size: .75em;
    font-weight: 800;
    border-radius: 5px;
    letter-spacing: 0.05em;
}

.programme-active {
    background: #9dfdcd;
    color: #4cc187;
}

.programme-completed {
    background: #eef;
    color: #99f;
    
}

.programme-inactive {
    background: #eee;
    color: #999;
    
}


.fal.fa-rating {
    font-weight: 300;
    &:hover { font-weight: 400 !important; }
    &.active { font-weight: 900 !important; }
}


.phase-pill {
    font-size: 9px;
    font-weight: 700;
    display: inline-block;
    padding: 3px 8px 3px 7px;
    background: black;
    border-radius: 50px;
}
.pending-pill {
    font-size: 9px;
    font-weight: 700;
    display: inline-block;
    padding: 3px 5px 3px 7px;
    background: #ffeeee;
    border-radius: 3px;
}



.tw-chip {
    padding: 4px 10px 4px 13px;
    display: block;
}
.mat-select[ng-reflect-panel-class~="mat-select-edit"] {
    padding: 5px 10px 4px 13px;
}
.tw-chip-container-b3 { border: 3px solid transparent; }
.tw-chip,
.mat-select[ng-reflect-panel-class~="mat-select-edit"] {
font-size: 9px !important;
line-height: 13px !important;
white-space: break-spaces;
letter-spacing: 0.08em;
font-weight: 700;
color: white;
// border-radius: 3px;
text-align: left;
text-transform: uppercase;
height: 100% !important;
text-align: center;
justify-content: center;
align-items: center;
display: flex;

// & , * { line-height: 1; }

&.green,
&[ng-reflect-panel-class~="green"] {
    background: $color-success;
}
&.yellow,
&[ng-reflect-panel-class~="yellow"] {
    background: $color-warning;
    font-size: 8px !important;
}
&.red,
&[ng-reflect-panel-class~="red"] {
    background: $color-primary; //$mat-red;
}
&.blue,
&[ng-reflect-panel-class~="blue"] {
    background: $color-blue;
}
&.info,
&[ng-reflect-panel-class~="info"] {
    background: #5fdbe8;
}
&.grey,
&[ng-reflect-panel-class~="grey"] {
    background: #ddd;
    color: #555;
}

.mat-select-value {
    overflow: visible;
    max-width: unset;
    .mat-select-value-text span {
    color: white;
    letter-spacing: 0.08em;
    line-height: 15px !important;
    }
}
.mat-select-arrow-wrapper {
    padding-right: 0px;
    display: none;
    .mat-select-arrow {
    color: white;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
    margin: 0px;
    }
}
}