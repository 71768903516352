@import "/src/assets/styles/variables";

app-public {
    // ngx-headroom
    .headroom {
        background: $color-narvik;
        z-index: 1050 !important;

        &:not(.headroom--unfixed) {
            box-shadow: 0 0 10px rgba(0,0,0,0.05), 0 0 3px rgba(0,0,0,0.08);
        }

        .navbar .navbar-nav .nav-item button, .navbar .side-nav-toggle {
            background: $color-narvik;
        }
    }
}

body.headroom--white app-public {
    .headroom, .headroom .navbar .navbar-nav .nav-item button, .navbar .side-nav-toggle {
        background: white !important;
    }
}