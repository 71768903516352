ngx-dropzone-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div.d-flex {
        i {
            font-size: 75px;
            margin-bottom: 15px;
        }
    }
}