ngx-avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;

    &.btn-upload:before, &:after {
        transition: 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        color: white;
        display: inline-grid;
        text-align: center;
        align-items: center;
    }

    &.btn-upload:not(.loading) {
        &:before {
            content: "\f0ee";
            font-size: 0.9em;
        }
        &:not(:hover):before {
            opacity: 0;
        }
        &:hover:before { 
            opacity: 1;
        }
    }

    &.btn-upload.loading:before, &:after {
        content: "\f3f4";
        font-size: 1em;
        -webkit-animation: fa-spin .75s linear infinite;
        animation: fa-spin .75s linear infinite;
    }

    &.btn-upload:before {
        z-index: 1;
        background: radial-gradient(#00000088, black);
    }

    &:after {
        z-index: -1;
        background: black;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .avatar-container {
        display: flex; 

        img.avatar-content {
            border-radius: unset !important;
            height: unset !important;
            min-height: 100%;
            margin-top: 50%;
            transform: translateY(-50%);
            background: white;
        }
        div.avatar-content {
            font-family: 'Montserrat';
            font-weight: 500;
            letter-spacing: .1em;
            width: 100%;
        }
    }
}