@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

$row-background-color: #f8f8f8;
$row-border-color: #ddd;
$row-left-padding: 1rem;

$color-active: #555;
$color-inactive: #999;

$color-blie: #999;

ngx-datatable.ngx-datatable.material {
    box-shadow: unset;

    &.inverted {
        datatable-header.datatable-header {
            background: #f0f0f0;

            .datatable-header-inner .datatable-header-cell {
                .datatable-header-cell-label, .sort-btn {
                    color: black;
                }
            }
        }
    }

    &:not(.inverted) {
        datatable-header.datatable-header {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            background: linear-gradient(#777, #000 5%, #333 95%, #777);
    
            .datatable-header-inner .datatable-header-cell {
                .datatable-header-cell-label, .sort-btn {
                    color: white;
                }
            }
        }
    }

    // Datatable Header
    datatable-header.datatable-header {
        @include static-overlay;

        .datatable-header-inner {
            height: 100%;

            .datatable-header-cell {
                padding: 0 $row-left-padding;
                margin: auto;
                background-color: transparent;
                white-space: normal;
                line-height: 1.25;

                &.text-center {
                    .datatable-header-cell-template-wrap {
                        justify-content: center;
                    }
                }
    
                .datatable-header-cell-label,
                .sort-btn {
                    text-transform: uppercase;
                    font-weight: 800;
                    font-size: 10px;
                    letter-spacing: 0.15em;
                }

                .sort-btn {
                    margin-left: .3rem;
                }

                &.text-small .sort-btn {
                    transform: translateY(2px);
                }

                .datatable-header-cell-template-wrap {
                    display: flex;
                    align-items: center;
                }

                &.text-small {
                  .datatable-header-cell-wrapper {
                    line-height: 1;

                    .datatable-header-cell-label {
                        font-size: 8px;
                    }
                  }
                }
                &.text-smaller {
                  .datatable-header-cell-wrapper {
                    line-height: 1;
                    
                    .datatable-header-cell-label {
                        font-size: 7px;
                    }
                  }
                }
            }
        }

        .datatable-row-right {
            background-image: none;
            background-color: transparent;
        }
    }


    // Datatable Body
    datatable-body.datatable-body {

        &::-webkit-scrollbar { display: none; }

        datatable-selection {
            datatable-scroller {
                width: 100% !important;
            }
        }
        
        // .datatable-row-right {
            // background-color: transparent;
        // }

        .datatable-row-wrapper {
            border-bottom: 1px solid $row-border-color;

            &:not(.row-focused):hover .datatable-body-row:not(.active) {
                &:before {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    background: black;
                    z-index: 1;
                }

                .datatable-row-group {
                    background-color: $row-background-color;
                }
            }

            .datatable-body-row {
                position: relative;

                &:hover,
                &:hover .datatable-row-group {
                    transition: none !important;
                }

                .bg-light-grey {
                  background: #aaaaaa11;
                }

                .bg-grey {
                    background: #aaaaaa22;
                }

                .datatable-body-cell {
                    padding: 0 $row-left-padding;

                    .datatable-body-cell-label {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .hint {
                            font-size: 9px;
                            line-height: 1.75;
                        }

                        & > * {
                            transition: 0.3s ease; 

                            &:first-child {
                                margin-top: auto;
                            }

                            &:last-child {
                                margin-bottom: auto;
                            }
                        }

                        button.action-buttons, a.action-buttons {
                            width: 50px;
                            display: inline-block;
                            text-align: center;
                            min-width: unset;
                            font-size: 18px;
                            margin: auto;
                            background-color: transparent;
                            color: #aaa;

                            &:hover {
                                color: black;
                            }

                            &[disabled] {
                                cursor: not-allowed !important;
                                color: rgba(0, 0, 0, 0.1) !important;
                                background-color: transparent !important;
                            }

                            i {
                                transform: translateY(-3px);
                            }
                        }
                    }
                
                }
            }

        }
    }


    // Datatable Footer
    datatable-footer.datatable-footer {
        border-top: 1px solid $row-border-color;

        .page-count {
            color: $color-inactive;
            padding-left: $row-left-padding;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 10px;
            letter-spacing: 0.15em;
            white-space: nowrap;
        }
        
        .datatable-pager {
            .pager {
                white-space: nowrap;
            }
            
            li {
                &:not(.active) a {
                    color: $color-inactive;
                }
    
                &.active a {
                    background-color: $row-background-color;
                    color: $color-active;
                }
            }
        }
    }


    // Datatable with Checkboxes
    .datatable-checkbox input[type="checkbox"] {
        &:before {
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid #f1f1ff;
        }

        &:after {
            width: 1.2rem;
            height: 1.2rem;
        }

        &:checked {
            &:before {
                height: 0.6rem;
                border-color: #000;
            }
        }
    }
}

@media (min-width: 576px) {
    ngx-datatable.ngx-datatable.material {
        // max-height: calc(100vh - 355px);
        min-height: 443px;
        height: calc(100vh - 207px);
    }
}

@media (max-width: 575px) {
    ngx-datatable.ngx-datatable.material {
        // max-height: calc(100vh - 395px);
        height: calc(100vh - 197px);


        datatable-footer.datatable-footer {
            .page-count { display: none; }
            .datatable-pager { text-align: center !important; }
        }
    }
}



ngx-datatable.ngx-datatable.material {

    datatable-body.datatable-body {

        .empty-row {
            height: 100%;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-size: 12px;
            font-weight: 600;
            color: #aaa;
            text-align: center;
        }

        .datatable-row-wrapper {
            &:not(:hover) .datatable-body-row {
                &.bg-red {
                    border-top: 1px solid #ffe0e0;
                    border-bottom: 1px solid #ffe0e0;
                    .datatable-row-group { background-color: #fff0f0; }
                }
                &.bg-yellow {
                    border-top: 1px solid #ececad;
                    border-bottom: 1px solid #ffffe0;
                    .datatable-row-group { background-color: #fffff0; }
                }
            }
            &:hover .datatable-body-row {
                &.bg-red {
                    border-top: 1px solid #ffdddd;
                    border-bottom: 1px solid #ffdddd;
                    .datatable-row-group { background-color: #ffe8e8 !important; }
                    &:before { background: #f5aaaa !important; }
                }
                &.bg-yellow {
                    border-top: 1px solid #e1e19d;
                    border-bottom: 1px solid #ffffdd;
                    .datatable-row-group { background-color: #ffffe8 !important; }
                    &:before { background: #c7c786 !important; }
                }
            }
        }
    }
}



// Inline Editable
ngx-datatable.ngx-datatable.material {

  .datatable-row-wrapper {
    .datatable-body-cell {

      &:not(.editable) {
        padding-left: 24px;
      }

      &.editable, .editable {
        padding: 0;
        position: relative;
        cursor: pointer !important;
        border: 3px solid transparent;

        .datatable-body-cell-label,
        div.editable-wrapper {
          height: 100%;
        }

        div.editable-wrapper {
          padding-left: 5px;
          display: flex;
          justify-content: center;

          span {
            margin: auto 0;
          }

          // mat-select,
          // mat-option,
          // .mat-slide-toggle .mat-slide-toggle-content {
          //   font-family: $font-proxima !important;
          // }
        }

        .mat-form-field {
          background: none !important;
        }

        .editable-wrapper:after,
        .mat-form-field:after {
          content: "";
          font-family: "Font Awesome 5 Pro";
          font-weight: 500;
          display: block;
          position: absolute;
          top: calc(50% - 3px);
          right: 3px;
          transform: translateY(-50%);
          transition: 0.3s ease;
        }

        .editable-wrapper.copy-wrapper:after {
          content: "";
        }

        .editable-wrapper:not(.editing):after,
        .mat-form-field:after {
          opacity: 0;
          color: #00000022;
        }

        .editable-wrapper:not(.editing):before {
          content: "click to edit";
          font-style: italic;
          color: #00000088;
          display: block;
          position: absolute;
          bottom: 0;
          left: 5px;
          right: 5px;
          transition: 0.3s ease;
          opacity: 0;
          font-size: 8px;
          letter-spacing: 0.15em;
        }

        .editable-wrapper.copy-wrapper:not(.editing):before {
          content: "Double click to copy the password";
        }

        &.bg-danger {
          background-color: #fc5e71bf !important;
          
          &:hover {
            border-color: white !important; 
          }
          .editable-wrapper:not(.editing):after,
          .editable-wrapper:not(.editing):before {
            color: white;
          }
        }
      }
    }

    &:not(.row-focused):not(:hover) .datatable-body-row.hint-row { opacity: 0.5; }

    &:not(.row-focused):hover .datatable-body-row:not(.bg-red):not(.bg-yellow):not(.active) {
      .datatable-row-group {
        .editable-wrapper span {
          // color: $mat-blue;
          color: $color-blue;
        }

        .datatable-body-cell.editable, .datatable-body-cell .editable {
          &:hover {
            background: rgba(255, 255, 255, 0.5);
            border: 3px dashed #00000022;
            cursor: text;

            .editable-wrapper:before {
              opacity: 1;
            }
          }

          &:hover .editable-wrapper:not(.editing) {

            &:after,
            .mat-form-field:after {
              opacity: 1;
            }
          }
        }
      }
    }

    .datatable-body-row.bg-red {
      .datatable-row-group {
        .editable-wrapper span {
          color:#00000088;
        }

        .datatable-body-cell.editable, .datatable-body-cell .editable {
          &:hover {
            background: rgba(255, 255, 255, 0.5);
            border: 3px dashed #00000022;
            cursor: text;

            .editable-wrapper:before {
              opacity: 1;
            }
          }

          &:hover .editable-wrapper:not(.editing) {
            &:after,
            .mat-form-field:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &.row-focused .datatable-body-row, .datatable-body-row.active {
      .datatable-row-group {
        .editable-wrapper.editing {
          span,
          input {
            color: #00000088;
          }

          &:after {
            opacity: 0;
          }
        }

        .datatable-body-cell.editable, .datatable-body-cell .editable {
          &:hover {
            background: rgba(255,255,255,0.05);
            
            .editable-wrapper {
              &:before {
                opacity: 0;
              }
            }
          }

          &:hover .editable-wrapper:not(.editing):after {
            opacity: 1;
            color: #00000088;
          }
        }
      }

    }
  }
}