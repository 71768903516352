@import "@teamhive/ngx-tooltip/assets/styles/styles.css";
@import "/src/assets/styles/variables";

.biohackk-theme {
   // --tooltip-color: #9df2a4;      
   --tooltip-arrow-color: #000000dd;
   --tooltip-background-color: #000000cc;
   --tooltip-font-size: 10px;
   --tooltip-font-weight: 400;
   --tooltip-font-style: normal;
   --tooltip-font-family: 'Montserrat';
   --tooltip-box-shadow: $drop-shadow;
   --tooltip-border-radius: 5px;
   --tooltip-text-align: center;
   --tooltip-padding: 5px;

   max-width: 300px !important;
}
